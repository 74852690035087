import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../utils/loading/loading';
import './Choicepage.css';
import { NavbarDesktop } from '../utils/navbar/desktop/navbar.desktop';
const FalseData = () => {
  return(
    {
      "message": "Filter fetched",
      "filter": [
          {
              "ref": "P643",
              "url": "https://misfat.com.tn/catalogue/reference.php?ref=P643",
              "desc": null,
              "marque": "misfat",
              "img": [
                  "https://gestion.misfatgroup.com/figures/misfat/310.jpg?1715942440"
              ]
          },
          {
              "ref": "P643",
              "url": "https://www.sogefifilterdivision.com/catalogues/FO/scripts/cat_fich_filtre.php?zone=FR&catalogue=PFX&lang=FR&ref_filtre=&searchref=P643&old_marque=",
              "desc": null,
              "marque": "purflux",
              "img": [
                  "https://www.sogefifilterdivision.com/catalogues/import/figures_gd/900.jpg"
              ]
          }
      ]
  }
  );
}


export const ChoicePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    console.log(location);
    const someProp = location.state.search;
    useEffect(() => {
        if (someProp == null || someProp == "")
            navigate('/search');
      const fetchData = FalseData();
      setData(fetchData);
    }, []);

    useEffect(() => {
      if (data.length !== 0) {
        setLoading(false);
      }
    }, [data])


  return(
    <div>
      <NavbarDesktop></NavbarDesktop>
    <div className='div'>
        { loading ?
        <Loading></Loading> : 
        <table>
        <thead>
            <tr>
                <th>Filtres</th>
                <th>Marques</th>
                <th>Image</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            { 
              data.filter.map((item) => {
                return(
                  <tr>
                    <td>{item.ref}</td>
                    <td>{item.marque}</td>
                    <td>
                      <img className='filter-img' src={item.img[0]}></img>
                    </td>
                    <td>{item.desc}</td>
                  </tr>
                )
              })
            }
        </tbody>
    </table>
        }
        </div>
        </div>
  )
};