


export class Network{
    apiKey = "";
    constructor(apiKey){
        this.apiKey = apiKey;
    }

    async get(url) {
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            this.ErrorGestion(response);
            return response;
        } catch (e) {
            if (e.response) {
                const statusCode = e.response.status;
                alert(`Error ${statusCode}: ${e.message}`);
            } else {
                alert("Error : réseaux");
            }
            throw e;
        }
    }


    async post(url, data) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            this.ErrorGestion(response);
            return response;
        } catch (e) {
            if (e.response) {
                const statusCode = e.response.status;
                console.log(`Error ${statusCode}: ${e.message}`);
                alert(`Error ${statusCode}: ${e.message}`);
            } else {
                alert("Error : réseaux");
            }
            throw e;
        }
    }
    
    ErrorGestion(response){
        if (response.status == 200)
            return;
        alert("ERROR: " + response.status + " " + response.statusText);
    }
}