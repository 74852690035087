import "./loading.css"

export const Loading = () => {
    return(

        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    )

};