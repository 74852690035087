import { Logo } from "../utils/logo/logo";
import "./searchpage.css"
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';


export const SearchPage = () => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const handleClick = () => {
        if (search !== null && search !== ""){
            navigate('/choice', {state : {search: search}});
        }
    };

    return (
        <div className="searchBar">
            <Logo size={90}></Logo>
            <input onChange={(val) => {setSearch(val.target.value)}} className="shadow-2xl"></input>
            <button className="shadow-2xl" onClick={handleClick}>Search</button>
        </div>
    );

};

