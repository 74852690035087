import "./Register.css";
import React, { useState, useRef, useEffect } from "react";
import { Stepper, Step } from 'react-form-stepper';
import { NavbarDesktop } from "../../utils/navbar/desktop/navbar.desktop";






const FourDigitInput = () => {
    const [digits, setDigits] = useState(['', '', '', '']);
    const inputsRef = useRef([]);
  
    const handleChange = (index, value) => {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      if (value && index < digits.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    };
  
    const handleKeyDown = (index, e) => {
      if (e.key === 'Backspace' && index > 0 && !digits[index]) {
        // Move focus to previous input field on backspace press
        inputsRef.current[index - 1].focus();
      }
    };
  
    return (
      <div>
        {digits.map((digit, index) => (
          <input
            key={index}
            ref={(input) => (inputsRef.current[index] = input)}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            style={{
              width: '30px',
              marginRight: '5px',
              textAlign: 'center',
            }}
          />
        ))}
        <TestValidation digits={digits}></TestValidation>
      </div>
    );
  };


  const TestValidation = ({digits}) => {
    const [validation, setValidation] = useState("Validation du code ...");
    const [compareDigit, setCompareDigit] = useState("");

    useEffect(() => {
        const compare = async () => {
          if (digits.join('').length === 4) {
            setValidation("Validation du code ...");
    
            // Use setTimeout instead of setInterval to run once after 1 second
            setTimeout(() => {
              setCompareDigit("1234");
    
              // Compare digits after setting compareDigit
              if (digits.join('') === compareDigit) {
                setValidation("Code Valide");
              } else {
                setValidation("Code Invalide");
              }
            }, 1000);
          } else {
            setValidation("");
          }
        };
        compare();
      }, [digits, compareDigit]);

    return(
        <div>
            <p>{validation}</p>
        </div>
    );
  };
  

export const Register = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [Validation, setValidation] = useState("");
    return(
        <div>
            <NavbarDesktop></NavbarDesktop>
            <div style={{zIndex : -4, display : "flex", flexDirection : "column", alignItems : "center"}}>
                <Stepper style={{zIndex : -1000}} activeStep={activeStep}>
                    <Step style={{zIndex : -1000}} label="Formulaire"/>
                    <Step style={{zIndex : -1000}} label="Validez votre adresse mail" />
                    <Step style={{zIndex : -1000}} label="Activez l'abonnement" />
                </Stepper>
            
                <div className="form-container text-center">
                {
                    activeStep === 0 ?
                    <div>
                        <p className="text-center title">Formulaire</p>
                        <div className="forms drop-shadow-2xl">
                            <div className="InputFieldDiv">
                                <input type="text" placeholder="Nom de la société" />
                            </div>
                            <div className="InputFieldDiv">
                                <input type="text" placeholder="Prénom" />
                            </div>
                            <div className="InputFieldDiv">
                                <input type="text" placeholder="Nom" />
                            </div>
                            <div className="InputFieldDiv">
                                <input type="email" placeholder="adresse mail" />
                            </div>
                            <div className="InputFieldDiv">
                                <input type="password" placeholder="Mot de passe" />
                            </div>
                            <div className="InputFieldDiv">
                                <input type="password" placeholder="Confirmer le mot de passe" />
                            </div>
                        </div>
                        </div>
                    : activeStep == 1 ?
                        <div>
                            <p className="text-center title">Validez Votre Adresse Mail</p>
                            <div className="text-center drop-shadow-2xl" style={{marginBottom : 30}}><FourDigitInput></FourDigitInput></div>
                        </div>
                     :
                    
                    <></>
                }
                </div>

                <div className="next-prev drop-shadow-2xl">
                    <button className="classic" onClick={() => {activeStep + 1 <= 2  ? setActiveStep(activeStep + 1) : <></>}}>
                        Suivant
                    </button>

                    <button className="classic" onClick={() => {activeStep - 1 >= 0  ? setActiveStep(activeStep - 1) : <></>}}>
                        Retour
                    </button>
                </div>
            </div>
        </div>
    );
};