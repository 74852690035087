import "./login.css"
import { NavbarDesktop } from "../../utils/navbar/desktop/navbar.desktop"
import React, {useEffect, useState} from "react"
import { Api } from "../../network/api"

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const testandlogin = async () => {
        if (email == "" || password == ""){
            alert("l'email et le mot de passe ne peuvent être vide");
            return;
        }
        if (!email.includes("@")){
            alert("l'email est invalide");
            return;
        }
        const login = await Api.Login(email, password);
        if (!login){
            alert("le login a échoué");
        }
        console.log(login);
        return login;
    }

    return(
        <div>
            <NavbarDesktop></NavbarDesktop>
            <div className="login-container"> 
                <img style={{marginBottom : 40}} src={require("../../assets/principal.png")}></img>
                <div className="input-container-login">
                    <input className="shadow-2xl" placeholder="email" type="email" onChange={(val) => {setEmail(val.target.value)}} ></input>
                    <input className="shadow-2xl" placeholder="password" type="password" onChange={(val) => {setPassword(val.target.value)}}></input>
                </div>
                <button className="button" onClick={async () => {await testandlogin()}}>Login</button>
            </div>
        </div>
    )
}