import React, {useState, useEffect} from "react";
import "./Acceuil.css";
import { NavbarDesktop } from "../utils/navbar/desktop/navbar.desktop";

export const Acceuil = () => {
    const [name , setName] = useState("");
    const [email , setEmail] = useState("");
    const [message , setMessage] = useState("");
    const [subject , setSubject] = useState("Avoir des informations");
    const [error, setError] = useState(false);


    const sendMail = () => {
        alert("ce systeme ne marche pas encore il est en cours de mise en place");
    };


    const TestForm = () => {
        if(name === "" || email === "" || message === ""){
            setError(true);
            return false;
        }
        setError(false);
        sendMail();
        return true;
    }


    return(
        <div className="body">
            <NavbarDesktop></NavbarDesktop> 
            <div className="header-container">
                <div>
                <div className="title-header"><span style={{color : "red"}}>Trouvez</span> les références et 
                <span style={{color : "red"}}> stockez</span> leurs prix en quelques clics !!!</div>
                <div className="button-container">
                    <button className="button shadow-2xl">Commencer</button>
                    <button className="button white shadow-2xl">En savoir plus</button>
                </div>
                </div>
                <img  src={require("../assets/principal.png")}></img>
            </div>
            <div className="body-container">
                <div className="title text-center">Qui sommes nous ?</div>
                
                <div className="text">
                <span style={{color : "red"}}>Σxplorer</span> est un outils en ligne qui permet de retrouver facilement et intuitivement
des filtres automobiles en fonction de l’une de ces références. Grace à votre compte
client, Vous pourrez stocké et importez les prix des filtres ainsi que de completer la base de données avec de nouvelles références !!!
                </div>

                <div className="imageText">
                    <img className="shadow-2xl" style={{borderRadius : "10px"}} src={require("../assets/filtres-principal.jpg")}></img>
                    <div className="text">
                        <div className="description">Trouvez facilement les références des filtres automobiles en fonction d'une seule d'entre elles</div>
                    </div>
                </div>
            </div>

            <div className="contact">
                <div className="title text-center">Contactez nous</div>
                <div className="formcontact  shadow-2xl">
                    <div className="formcontact-2">
                        <input className="input" placeholder="nom *" onChange={(val) => {setName(val.target.value)}}></input>
                        <input className="input" placeholder="email *" type="email" onChange={(val) => {setEmail(val.target.value)}}></input>
                    </div>
                    <select onChange={(val) => {setSubject(val.target.value)}}>
                        <option>Avoir des informations</option>
                        <option>Faire une proposition</option>
                        <option>Demande d'aide</option>
                    </select>
                    <textarea placeholder="message *" onChange={(val) => {setMessage(val.target.value)}}></textarea>
                    <span>*Ces champs sont obligatoires</span>
                    {
                        error ? <span style={{color : 'red', fontWeight : "bold"}}>un champs n'a pas était renseigné</span> : <></>
                    }
                    <button className="button" onClick={() => {TestForm()}}>Envoyer</button>
                </div>
            </div>
            
            <div style={{display: "flex", justifyContent : "space-between", flexDirection : "row", paddingInline : 30}}>
      <span style={{fontSize : "90%"}}>Copyright  ©2024 SigmaRechange</span>
      <span style={{fontSize : "90%"}}>developed by <a href='https://github.com/Codrux2200' style={{color : 'red'}}>Saad Berrada</a></span>
    </div>
        </div>

    
    );
}