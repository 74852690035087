import React from 'react';
import { NavbarDesktop } from "./utils/navbar/desktop/navbar.desktop";
import { Acceuil } from './Acceuil/Acceuil';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Register } from './connexion/register/Register';
import { SearchPage } from './searchpage/searchpage';
import { ChoicePage } from './ChoicePage/Choicepage';
import { Login } from './connexion/login/login';
function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Acceuil></Acceuil>}/>
        <Route path="/Register" element={<Register/>}/>
        <Route path = "/search" element = {<SearchPage></SearchPage>}></Route>
        <Route path='/choice' element = {<ChoicePage></ChoicePage>}></Route>
        <Route path='/login' element = {<Login></Login>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;