import React from "react";


export const Logo = ({size}) => {
    return(
        <div style={{display : "flex", width : "auto", height : "auto"}}>
            <p style={{color : "red", fontSize : size, fontWeight: "bold"}}>Σxplorer</p>
            <p style={{color : "red", fontSize : "italic", marginLeft : 5}}>Alpha 1.0</p>
        </div>

    );
}

