import "../navbar.css"
import React, {useState} from "react";
import { Logo } from "../../logo/logo";
import DropDownsvg from "../../../assets/svg/dropdown.svg";
import { Link } from 'react-router-dom'; // Import Link component from React Router

export const Dropdown = () => {
    return( 
        <div className="dropdown drop-shadow-lg">
            <Link to="/prix">nos prix</Link>
            <Link to="/fonctionalites">nos fonctionalités</Link>
            <Link to="/login">connexion</Link>
            <Link to="/register">crée un compte</Link>
        </div>
    );
}

export const NavbarDesktop = () => {
    const [displayStyle, setDisplayStyle] = useState('none');
    return (
        <div>
        <div className="navbar-container drop-shadow-lg backdrop-blur-sm bg-white/30">
            <Link to="/"><Logo size={30}></Logo></Link>
            <div className="link-container desktop">
                {/* Use Link instead of anchor tags */}
                <Link to="/prix">nos prix</Link>
                <Link to="/fonctionalites">nos fonctionalités</Link>
                <Link to="/login">connexion</Link>
                <Link to="/register">crée un compte</Link>
            </div>
            <div className="drop-down mobile" onClick={() => {displayStyle == "none" ? setDisplayStyle('flex') : setDisplayStyle('none')}}>
                <img style={{ width: "40px", height: "auto" }} src={DropDownsvg} alt="Dropdown"></img>
            </div>
        </div>
        <div style={{ display: displayStyle }}><Dropdown></Dropdown></div>
         </div>
    );
}
