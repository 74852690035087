import { Network } from "./network";


class api extends Network{

    url = "82.165.106.80:3000"

    constructor(){
        super();
    }

    async Login(login, password){
        let returnData = await this.post(this.url + "/login", {email: login, password: password});
        returnData = await returnData.json();
        if (returnData && returnData.token){
            localStorage.setItem("token", returnData.token);
            console.log("get token");
        }
        console.log(returnData);
    }

    async register(username, email, password, Societe){
        let returnData = await this.post(this.url + "/register", {username: username, email: email, password: password, Societe : Societe});
        returnData = await returnData.json();
        console.log(returnData);
    }

}

export const Api = new api();